.fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff; /* Color de fondo de la barra */
    z-index: 1000; /* Z-index alto para que esté por encima del contenido */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra opcional */
    /* Añade cualquier otro estilo que desees */

  }




  .fixed-navbar-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff; /* Color de fondo de la barra */
    z-index: 1000; /* Z-index alto para que esté por encima del contenido */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra opcional */
    padding-bottom: 10px;
    /* Añade cualquier otro estilo que desees */

  }