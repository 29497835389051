.Personal{

    font-family: 'montserratregular';
    background-color: #FAFAFA;
    padding:0px;


  
}


.Personal h1 {

  font-family: 'montserratbold';
  font-size:50px;
  color:black;
}

.Personal h2 {

  font-family: 'montserratbold';
  font-size:17px;
  color:black;
}

.Personal h6 {

  font-family: 'montserratregular';
  font-size:13px;
  color:black;
}

.Personal h3 {

  font-family: 'montserratbold';
  font-size:15px;
  color:black;
}

.Personal h4 {

  font-family: 'montserratbold';
  font-size:13px;
  color:black;
}



.Personal p {


  font-family: 'montserratbold';
  font-size:13px;
  color:black;
}


.Personal p {


  font-family: 'montserratbold';
  font-size:13px;
  color:black;
}

.Personal input {

  padding:5px;
  font-size:13px;
  color:black;   
  width: 100%;  
  border-radius: 5px;
  border: 1px solid #CCC;

}

.Personal input::placeholder {
  color: #A0A0A0;
}

.Personal input:focus {
  border-color: black;
}


.Personal button{

  width: 100%;
  background-color: #0074EF;  
  Border:none;
  border-radius:5px;
  padding:5px;
  color: white;
  font-size:13px;
}


.Personal a{

  width: 100%;
  color: black;  
  padding:5px;
  font-size: 12px;
  text-decoration: none;
}

.Personal p{

 
  color: black;  
  padding:5px;
  font-size: 12px;
  text-decoration: none;
}

.Personal .relation p{
  width:100%;
  color: black;  

}

.Personal hr{
  color:#9D9D9D;
}

.popup {
  display: none;
  position: left;
  background-color: white;

  color:red;
  border-radius: 5px;
  padding: 5px;
  margin-top:2px;
  z-index: 1;
  font-size:10px;
}


.modalLoadPicture button{

  font-family: 'montserratregular';
  font-size: 12px;
  width: 100%;
  background-color: #E8E8E8;  
  Border:1px solid #ccc;
  border-radius:5px;
  padding:5px;

}


.modalLoadPictureButton {
  font-family: 'montserratregular';
  font-size: 12px;
  width: 100%;
  background-color: #E8E8E8;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  display: inline-block;
  text-align: center;
}

/* Oculta el input de tipo archivo */
#loadPicture {
  display: none;
}

/* Estilo para el botón cuando se pasa el cursor por encima */
.modalLoadPictureButton:hover {
  background-color: #D8D8D8;
}



.select2 {
  font-family: 'montserratregular';
  padding:5px;
  font-size:13px;
  color:black;   
  width: 50%;  
  border-radius: 5px;
  border: 1px solid #CCC;
  width: 100% !important;
 } 

 .select2-container {
  font-family: 'montserratregular';
  font-size: 13px;
  color: black;
  width: 100% !important;
}





.Personal .readonlyInput input {

  padding:5px;
  font-size:13px;
  color:#AAAAAA;   
  width: 100%;  
  border-radius: 5px;
  border: 1px solid #CCC;
  background-color: #F4F4F4;

}

.Personal input:focus {
  border-color: black;
}

.Personal select {

  padding:5px;
  font-size:13px;
  color:black;   
  width: 100%;  
  border-radius: 5px;
  border: 1px solid #CCC;

}

.Personal select::placeholder {
  color: #A0A0A0;
}