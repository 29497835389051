.Personal-mobile{

    font-family: 'montserratregular';
    background-color: #FAFAFA;
    padding:0px;


  
}


.Personal-mobile h1 {

  font-family: 'montserratbold';
  font-size:50px;
  color:black;
}

.Personal-mobile h2 {

  font-family: 'montserratbold';
  font-size:17px;
  color:black;
}

.Personal-mobile h6 {

  font-family: 'montserratregular';
  font-size:13px;
  color:black;
}

.Personal-mobile h3 {

  font-family: 'montserratbold';
  font-size:15px;
  color:black;
}

.Personal-mobile h4 {

  font-family: 'montserratregular';
  font-size:13px;
  color:black;
  text-align: 'justify';

}



.Personal-mobile p {


  font-family: 'montserratbold';
  font-size:13px;
  color:black;
}


.Personal-mobile p {


  font-family: 'montserratbold';
  font-size:13px;
  color:black;
}

.Personal-mobile input {

  padding:5px;
  font-size:13px;
  color:black;   
  width: 100%;  
  border-radius: 5px;
  border: 1px solid #CCC;

}

.Personal-mobile input::placeholder {
  color: #A0A0A0;
}

.Personal-mobile input:focus {
  border-color: black;
}


.Personal-mobile button{

  width: 100%;
  background-color: #0074EF;  
  Border:none;
  border-radius:5px;
  padding:5px;
  color: white;
  font-size:13px;
}


.Personal-mobile a{

  width: 100%;
  color: black;  
  padding:5px;
  font-size: 12px;
  text-decoration: none;
}

.Personal-mobile p{

 
  color: black;  
  padding:5px;
  font-size: 12px;
  text-decoration: none;
}

.Personal-mobile .relation p{
  width:100%;
  color: black;  

}

.Personal-mobile hr{
  color:#9D9D9D;
}

.popup {
  display: none;
  position: left;
  background-color: white;

  color:red;
  border-radius: 5px;
  padding: 5px;
  margin-top:2px;
  z-index: 1;
  font-size:10px;
}


.modalLoadPicture button{

  font-family: 'montserratregular';
  font-size: 12px;
  width: 100%;
  background-color: #E8E8E8;  
  Border:1px solid #ccc;
  border-radius:5px;
  padding:5px;

}


.modalLoadPictureButton {
  font-family: 'montserratregular';
  font-size: 12px;
  width: 100%;
  background-color: #E8E8E8;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  display: inline-block;
  text-align: center;
}

/* Oculta el input de tipo archivo */
#loadPicture {
  display: none;
}

/* Estilo para el botón cuando se pasa el cursor por encima */
.modalLoadPictureButton:hover {
  background-color: #D8D8D8;
}



.select2 {
  font-family: 'montserratregular';
  padding:5px;
  font-size:13px;
  color:black;   
  width: 50%;  
  border-radius: 5px;
  border: 1px solid #CCC;
  width: 100% !important;
 } 

 .select2-container {
  font-family: 'montserratregular';
  font-size: 13px;
  color: black;
  width: 100% !important;
}





.Personal-mobile .readonlyInput input {

  padding:5px;
  font-size:13px;
  color:#AAAAAA;   
  width: 100%;  
  border-radius: 5px;
  border: 1px solid #CCC;
  background-color: #F4F4F4;

}

.Personal-mobile input:focus {
  border-color: black;
}

.Personal-mobile select {

  padding:5px;
  font-size:13px;
  color:black;   
  width: 100%;  
  border-radius: 5px;
  border: 1px solid #CCC;

}

.Personal-mobile select::placeholder {
  color: #A0A0A0;
}