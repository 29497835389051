.startBody{

    font-family: 'montserratregular';
    background-color: #FAFAFA;
    padding:0px;

  
}

@font-face {
  font-family: montserratbold;
  src: url('../fonts/montserrat-bold.ttf'); 
}

@font-face {
  font-family: montserratregular;
  src: url('../fonts/montserrat-regular.ttf'); 
}

h1 {

  font-family: 'montserratbold';
  font-size:50px;
  color:black;
}

 h2 {


  font-size:20px;
  color:black;
}


h3 {

  font-family: 'montserratbold';
  font-size:20px;
  color:black;
}




 p {


  font-size:11px;
  color:black;
  font-family: 'montserratbold';
}

 input {

  padding:5px;
  font-size:12px;
  color:black;   
  width: 100%;  
  border-radius: 5px;
  border: 1px solid #CCC;

  font-family: 'montserratregular';

}

 input::placeholder {
  color: #A0A0A0;

  font-family: 'montserratregular';
}

 input:focus {
  border-color: black;
}


select {

  padding:5px;
  font-size:13px;
  color:black;   
  width: 100%;  
  border-radius: 5px;
  border: 1px solid #CCC;

  font-family: 'montserratregular';

}

select::placeholder {
  color: #A0A0A0;

  font-family: 'montserratregular';
}

select:focus {
  border-color: black;
}


 button{

  width: 100%;
  background-color: #0074EF;  
  Border:none;
  border-radius:5px;
  padding:5px;
  color: white;
  font-size:12px;
  font-family: 'montserratbold';
}


 a{

  width: 100%;
  color: #0074EF;  
  padding:5px;
  font-size:12px;
  text-decoration: none;
  font-family: 'montserratbold';
}

 hr{
  color:#C2C1C1;
}

.popup {
  display: none;
  position: left;
  background-color: white;

  color:red;
  border-radius: 5px;
  padding: 5px;
  margin-top:2px;
  z-index: 1;
  font-size:10px;
}


.grey-font-modal {
  /* Agrega aquí tus estilos personalizados para el párrafo */
  font-size: 13px;
  font-family: 'montserratregular';
  color: #909090;
  
}