body {
    margin: 0;
    padding: 0;
}

.App {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.crop-container {
    position: absolute;
    top: 40px;
    /* Aumenta el espacio para dar cabida a .closeBar */
    left: 50px;
    right: 50px;
    bottom: 130px;
    /* Espacio para los controles */
    z-index: 1;
    /* Agrega un z-index para establecer la capa de renderizado */
}

.crop-container-station {
    
    top: 10px;
    /* Aumenta el espacio para dar cabida a .closeBar */


    /* Espacio para los controles */
    z-index: 1;
    /* Agrega un z-index para establecer la capa de renderizado */
}


.controls {
    position: absolute;
    bottom: 85px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 40px;
    /* Reduce la altura de .controls para dar espacio a .imageInput */
    display: flex;
    align-items: center;
}

.controls-station {

    bottom: 85px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 40px;
    /* Reduce la altura de .controls para dar espacio a .imageInput */
    display: flex;
    align-items: center;
}


.slider {
    padding: 22px 0px;
}


.closeBar {
    position: absolute;
    top: 0;
    /* Coloca .closeBar en la parte superior */
    left: 0;
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    z-index: 2;
    /* Aumenta el z-index para que esté por encima de .crop-container */
}

.imageInput {
    position: absolute;
    bottom: 40px;
    /* Alinea .imageInput en la parte inferior */
    left: 50%;
    width: 30%;
    transform: translateX(-50%);
    height: 30px;
    /* Ajusta la altura de .imageInput para que no se superponga con .controls */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E8E8E8;
    font-family: 'montserratregular';
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;



}

.imageInput-station {
 
    bottom: 40px;
    /* Alinea .imageInput en la parte inferior */
    left: 50%;
    width: 30%;
    transform: translateX(-50%);
    height: 30px;
    /* Ajusta la altura de .imageInput para que no se superponga con .controls */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E8E8E8;
    font-family: 'montserratregular';
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;



}

.imageInput p {
    font-family: 'montserratregular';
    font-size: 12px;
    padding-top: 15px;
}

.crop-container p {
    font-family: 'montserratregular';
    font-size: 20px;

}




.imageInput input[type="file"] {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
    /* Superpone el input sobre el texto */
}



.imageInput::before {

    display: block;
    text-align: center;
    line-height: 30px;
}

.imageInput:hover {
    background-color: #D3D3D3;
    /* Cambia el color de fondo al pasar el ratón */
}

.saveButton {

    position: absolute;
    bottom: 17px;
    /* Alinea .imageInput en la parte inferior */
    left: 50%;
    width: 30%;
    transform: translateX(-50%);
    height: 0px;
    /* Ajusta la altura de .imageInput para que no se superponga con .controls */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E8E8E8;
    font-family: 'montserratregular';
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;



}

.saveButton button {

    background-color: #E8E8E8;
    font-family: 'montserratregular';
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color:black
}