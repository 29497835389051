.genericFooter{

    font-family: 'montserratbold';
    background-color: white;
    padding:0px;
    text-align: left;

  
}

@font-face {
  font-family: montserratbold;
  src: url('../fonts/montserrat-bold.ttf'); 
}

@font-face {
  font-family: montserratregular;
  src: url('../fonts/montserrat-regular.ttf'); 
}



.genericFooter a{

    width: 100%;
    color: #959595;  
    padding:5px;
    font-size: 11.5px;
    text-decoration: none;
    padding:0px;
  }
.genericFooter a{

    width: 100%;
    color: #959595;  
    padding:5px;
    font-size: 11.5px;
    text-decoration: none;
    padding:0px;
  }

  .genericFooter p{

    width: 100%;
    color: #959595;  
    padding:5px;
    font-size: 11.5px;
    text-decoration: none;
    margin:0px;
    padding:0px;
  }